import React from 'react'
import { Link } from "react-router-dom";
import styled, {createGlobalStyle} from 'styled-components'
import LogoImg from '../../assets/images/logo.svg';
import bnppCondBold from '../../assets/fonts/BNP-Font/bnpp-sans-cond-bold-v2.ttf';
import bnppBold from '../../assets/fonts/BNP-Font/bnpp-sans-bold.ttf';
import bnppLight from '../../assets/fonts/BNP-Font/bnpp-sans-light.ttf';
import bnppCondLight from '../../assets/fonts/BNP-Font/bnpp-sans-cond-light-v2.ttf';

const CreateGlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'bnpp-sans-bold';
        src: url(${bnppBold});
    }
    @font-face {
        font-family: 'bnpp-sans-light';
        src: url(${bnppLight});
    }
`

const Nav = styled.nav`
    height: 80px;
    display: flex;
    background-color: #fffff;
    align-items: center;
`

const Container = styled.div`
    width: 100%;
    padding: 0 25px;
    display: flex;
    align-items: center;
`

const Logo = styled.img`
    height: 40px;
    flex: 1;
`

const Slogan = styled.p`
    font-family: 'bnpp-sans-bold';
    font-size: 18px;
    line-height: 0;
`

const Slogan2 = styled(Slogan)`
    font-size: 9px;
    font-family: 'bnpp-sans-light';
`

const ContainerSlogan = styled.div`
    right: 0;
`

class Navbar extends React.Component{
    render(){
        return(
            <Nav>
                <CreateGlobalStyle />
                <Container>
                    <Link style={{flex: 1}} to="/">
                        <Logo src={LogoImg} />
                    </Link>
                    <ContainerSlogan>
                        <Slogan>For the many journeys in life*</Slogan>
                        <Slogan2>*Pour tous les trajets de la vie</Slogan2>
                    </ContainerSlogan>
                </Container>
            </Nav>
        )
    }
}

export default Navbar;