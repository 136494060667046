import React from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import ToggleSwitch from './ToggleSwitch'
import _ from 'lodash'
import { connect } from "react-redux";
import bnppCondBold from '../../assets/fonts/BNP-Font/bnpp-sans-cond-bold-v2.ttf';
import bnppBold from '../../assets/fonts/BNP-Font/bnpp-sans-bold.ttf';
import bnppLight from '../../assets/fonts/BNP-Font/bnpp-sans-light.ttf';
import bnppCondLight from '../../assets/fonts/BNP-Font/bnpp-sans-cond-light-v2.ttf';
import ArvalImg from '../../assets/images/arval.svg';
import ChefDeParcImg from '../../assets/images/chef-de-parc.svg';
import ConducteurImg from '../../assets/images/conducteur.svg';
import LineButtonImg from '../../assets/images/line_button.svg';
import { Link } from "react-router-dom";

const CreateGlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'bnpp-sans-cond-bold-v2';
        src: url(${bnppCondBold});
    }
    @font-face {
        font-family: 'bnpp-sans-cond-light-v2';
        src: url(${bnppCondLight});
    }
    @font-face {
        font-family: 'bnpp-sans-bold';
        src: url(${bnppBold});
    }
    @font-face {
        font-family: 'bnpp-sans-light';
        src: url(${bnppLight});
    }
`

const Container = styled.div`
    position: relative;
    margin: 50px 0;
    left: 55px;
    width: 700px;
`

const ContentHeader = styled.div`
    background-color: #1d6d5e;
    height: 120px;
    width: 700px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`

const BlockTitle = styled.div`
    background-color: #1d6d5e;
    color: #fff;
    margin-left: 16px;
    position: relative;
    border-radius: 38px;
    padding: 10px 15px;
    padding-right: 20px;
    justify-content: center;
    font-size: 22px;
    font-family: 'bnpp-sans-cond-light-v2';
    align-items: center;
    display: inline-flex;
    top: -29px;
    max-width: 170px;
    text-transform: uppercase;
`

const Title = styled.p`
    flex: 2;
    color: #fff;
    font-weight: bold;
    font-size: 22px;
    font-family: 'bnpp-sans-cond-bold-v2';
    margin-top: 18px;
`

const Number = styled.span`
    font-size: 1.75rem!important;
    box-sizing: border-box;
    display: inline-block;
    height: 36px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    padding: 6px 13px;
    font-weight: 700;
    line-height: 1;
    margin-right: 11px;
    margin-top: 2px;
    z-index: 1;
    color: #fff;
`

const ContainTitle = styled.div`
    height: 20px;
`

const ContainBlockSwitch = styled.div`
    display: flex;
    margin-top: 22px;
    margin-left: 25px;
`

const ContainSwitch = styled.div`
    display: flex;
`

const AOS = styled.p`
    color: #fff;
    font-family: 'bnpp-sans-cond-bold-v2';
    font-size: 20px;
`

const Svg = styled.img`
    flex: 2;
    margin-left: 51px;
    height: 119px;
    position: relative;
    top: -71px;
`
const Svg1 = styled.img`
    height: 35px;
    box-sizing: border-box
    margin: 0px 8px;
`

const FieldsContainer = styled.div`
    height: 275px;
    overflow-y: scroll;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    opacity: ${props => props.visible === 0 ? 0.5 : 1};

`

const FieldHead1 = styled.div`
    flex: 1;
    background-color: #15946d;
    padding: 11px 15px;
    border-bottom: 1px solid #fff;
    opacity: ${props => props.visible === 0 ? 0.4 : 1};

`
const FieldHead2 = styled.div`
    background-color: #81ba35;
    flex: 1;
    padding: 11px 15px;
    border-bottom: 1px solid #fff;
    opacity: ${props => props.visible === 0 ? 0.4 : 1};

`
const FieldHead3 = styled.div`
    flex: 1;
    background-color: #dbdd3b;
    padding: 11px 15px;
    border-bottom: 1px solid #fff;
    opacity: ${props => props.visible === 0 ? 0.4 : 1}
`

const BlockElement = styled.div`
    border-bottom: 1px solid #fff;
    padding: 11px 10px;

`

const BlockElementAOS = styled(BlockElement)`
    background-color: #ff7a37;

`

const BlockElementPlusArval = styled(BlockElement)`
    background-color: #ffcb05;

`

const BlockContent = styled.div`
    font-family: 'bnpp-sans-light';
    display: flex;
`  

const ContentP = styled.div`
    margin: 0;
    font-size: 0.8em;
    font-family: 'bnpp-sans-bold';
    line-height: 1.5;
    p {
        margin: 0;
    }
`

const File = styled.a`
    font-size: 10px;
    background-color: #fff;
    border-radius: 10px;
    padding: 4px 9px;
    text-decoration: none;
    font-family: 'bnpp-sans-bold';
    color: #1d6d5e;
    display: inline-block;
    margin-left: 20px;
`

const BlockContentAOS = styled(BlockContent)`
    background-color: #ff7a37;
    color: white;
`  
const Column1 = styled.div`
    flex: 1;
    min-height: 217px;
    background-color: #15946d;
    border-bottom: 1px solid #fff;
    opacity: ${props => props.visible === 0 ? 0.4 : 1};
`
const Column2 = styled.div`
    flex: 1;
    min-height: 217px;
    background-color: #81ba35;
    opacity: ${props => props.visible === 0 ? 0.4 : 1} !important;
`
const Column3 = styled(Column1)`
    background-color: #dbdd3b;
    opacity: ${props => props.visible === 0 ? 0.4 : 1} !important;
`

const Row = styled.div`
    display: flex;
`
    
const ContainCommunity = styled.div`
    position: absolute;
    width: auto;
    bottom: 40px;
    left: 51px;
`

const Head = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const ClickMore = styled.span`
    background-color: #ffcb05;
    color: #000;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    box-sizing: border-box
    margin: 0px 8px;
    line-height: 1.8;
    text-align: center;
    font-weight: bold;
    box-shadow: 0px 1px 2px #0b4c38;
    `
    
    const ClickMoreLess = styled(ClickMore)`
    line-height: 0.9;
    font-size: 25px;
    box-shadow: inset 0px 1px 2px #0b4c38;
`

const HeadTitle = styled.h3`
    box-sizing: border-box
    margin: 0px 8px;
    font-family: 'bnpp-sans-cond-bold-v2';
    text-transform: uppercase;
    font-size: 20px;
`

const Nbr = styled.span`
    font-family: 'bnpp-sans-bold';
    display: inline-block;
    height: 16px;
    background-color: #fff;
    border-radius: 15px;
    padding: 2px 5px;
    font-weight: 700;
    line-height: 1.2;
    margin-right: 5px;
    margin-top: -2px;
    z-index: 1;
    color: black;
`

// ButtonLevel

const ButtonLevel = styled.a`
    background-color: ${props => props.clickLevel ? '#1d6d5e' : '#fff'};
    color: ${props => props.clickLevel ? '#fff' : '#1d6d5e'};
    border-radius: 30px;
    // color: #1d6d5e;
    padding: 10px 13px;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 10px;
    display: block;
    text-align: center;
    width: 100%;
    max-width: 150px;
    line-height: 1.2;
    font-family: 'bnpp-sans-cond-bold-v2';
    text-decoration: none;
    
 
    `
    
    const ContainerLevel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ButtonAOS = styled(ButtonLevel)`
    border: 2px solid #ff7a37;
`

const BackgroundLine = styled.div`
    right: -240px;
    width: 200px;
    height: 395px;
    padding: 0 20px;
    bottom: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: url(${LineButtonImg}) no-repeat;
    background-position: -56px bottom;
    background-size: 180px;
`

class Commande extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            arval: false,
            checked: false,
            idButton: null,
            slug: null,
            isMyArvalCommunity: null
        };

        this.ToggleClick = this.ToggleClick.bind(this);
        this.FilterArval2 = this.FilterArval2.bind(this);
        this.arvalcontent = this.arvalcontent.bind(this);
        this.chefcontent = this.chefcontent.bind(this);
        this.conducContent = this.conducContent.bind(this);
    }

    pushId(e,id){
        this.setState({
            idButton: id
        })
        this.props.history.push({
            pathname: `${id}`,
            search: `?id=${id}`,
        })

     localStorage.setItem('idButton', id);
    }

    toggle(id) {
        this.props.toggle(id);
        this.pushId(null, id);
    }
    componentDidMount() {
        //this.props.toggle(12);
      
      //  console.log(this.props.toggle('les-prestations-de-services'));
    }

    componentDidUpdate(prevProps, prevState) {
        let previousClick = parseInt(localStorage.getItem('idButton'));
        if(!previousClick) previousClick = null;
        if(prevState.slug !== this.props.slug) {

            this.setState({
                slug: this.props.slug,
                idButton: previousClick
            })
            
            const urlParams = new URL(window.location);
            const myParam = urlParams.searchParams.get('id');

            if(myParam == null){
                this.setState({
                    idButton: null
                })
            }
        }
     }

    ////////////////////////////// ARVAL
    FilterArval(param){
        if(param){
            const Groups = _.filter(param.contents, function(o) { return o.actor.id === 1; });
            return Groups;
        }
    }

    FilterArval2(param){
        let id = this.state.idButton;
        const Groups = _.filter(param, function(o) { 
            return o.id === id;
        });
        
        if(id !== undefined){
            return Groups;
        }
    }

    arvalcontent(param){
        if(param.length === 0){
            return null;
        }
        const Groups2 = _.filter(param[0].contents, function(l) { 
            return l.actor.id === 1; 
        });
        return Groups2;
    }

    ////////////////////////////// CHEF
    FilterChef(param){
        if(param){
            const Groups = _.filter(param.contents, function(o) { return o.actor.id === 2; });
            return Groups;
        }
    }

    FilterChef2(param){
        let id = this.state.idButton;
        const Groupschef = _.filter(param, function(o) {
            return o.id === id;
        });

        if(id !== undefined){
            return Groupschef;
        }
    }

    chefcontent(param){
        if(param.length === 0){
            return null;
        }
        const Groupschef2 = _.filter(param[0].contents, function(l) { 
            return l.actor.id === 2; 
        });
        return Groupschef2;
    }

    ////////////////////////////// CONDUCTEUR
    // WITHOUT CLICK
    FilterConduc(param){
        if(param){
            const Groups = _.filter(param.contents, function(o) { return o.actor.id === 3; });
            return Groups;
        }
    }

    // ON CLICK
    FilterConduc2(param){
        let id = this.state.idButton;
        const Groupschef = _.filter(param, function(o) {
            return o.id === id;
        });

        if(id !== undefined){
            return Groupschef;
        }
    }

    conducContent(param){
        if(param.length === 0){
            return null;
        }
        const Groupschef2 = _.filter(param[0].contents, function(l) {
            return l.actor.id === 3; 
        });
        return Groupschef2;
    }

    /////////// CLICK

    ClickArval(e){
        e.preventDefault();
        this.setState({
            arval: !this.state.arval,
        })
    }
    
    ToggleClick(e){
        this.setState({
            checked: e.target.checked
        })
    }

    checkVisible(var1, var2, state) {
        if(var1 && !var2) {
          return var1
        } else if (!var1 && !var2) {
        //   console.log('elseif1', !var1 && !var2);
          return 0;
        } else if (!var1 && var2 > 0 && state) {
        //   console.log('elseif2', var2);
          return var2;
        } else {
        //   console.log('else', var1);
          return var1;
        }
      }

    
    render(){
        const { filter, buttonName, parcours } = this.props;
        const isMyArvalCommunity =  _.find(parcours, { isMyArvalCommunity: true });

        //console.log(isMyArvalCommunity);
        /////////// AOS : FALSE, IsPlus : FALSE
        
        const ContentArval = _.filter(this.state.idButton ? this.arvalcontent(this.FilterArval2(buttonName)) :this.FilterArval(buttonName[0]),  function(o) { 
            // console.log("numa :" , test);
            return o.isAOS === false && o.isPlusArval === false; 
        });
        
        const ContentChef = _.filter(this.state.idButton ? this.chefcontent(this.FilterChef2(buttonName)) :this.FilterChef(buttonName[0]),  function(o) { 
            return o.isAOS === false && o.isPlusArval === false; 
        });
        
        const ContentConduc = _.filter(this.state.idButton ? this.conducContent(this.FilterConduc2(buttonName)) :this.FilterConduc(buttonName[0]),  function(o) { 
            return o.isAOS === false && o.isPlusArval === false; 
        });
        
        /////////// AOS : TRUE
        
        const AOStrueArval = _.filter(this.state.idButton ? this.arvalcontent(this.FilterArval2(buttonName)) :this.FilterArval(buttonName[0]),  function(o) { 
            return o.isAOS === true; 
        });
        const AOStrueChef = _.filter(this.state.idButton ? this.chefcontent(this.FilterChef2(buttonName)) :this.FilterChef(buttonName[0]),  function(o) { 
            return o.isAOS === true; 
        });
        const AOStrueConduc = _.filter(this.state.idButton ? this.conducContent(this.FilterConduc2(buttonName)) :this.FilterConduc(buttonName[0]),  function(o) { 
            return o.isAOS === true; 
        });

        // AOS: false button
        const AOSbuttonFalse = _.filter(buttonName,  function(o) { 
            // console.log({o});
            return o.isAOS === false;
        });
        // AOS: true button
        const AOSbuttonTrue = _.filter(buttonName,  function(o) { 
            return o.isAOS === true;
        });
        
        /////////// IsPlus : TRUE
        
        const IsPlusTrueArval = _.filter(this.state.idButton ? this.arvalcontent(this.FilterArval2(buttonName)) :this.FilterArval(buttonName[0]),  function(o) { 
            return o.isPlusArval === true; 
        });

        console.log({filter})

        return (
             <div>
             <Container>
                 <CreateGlobalStyle />
                 <ContentHeader
                     color={filter.color}
                 >
                     <ContainTitle>
                         <BlockTitle
                            color={filter.color}
                         >
                         <Number>{filter.position + 1}</Number>
                             
                            {filter.name}</BlockTitle>
                     </ContainTitle>
                     <ContainBlockSwitch>
                         <Title>{filter.slogan}</Title>
                         <ContainSwitch>
                            <ToggleSwitch
                                checked={this.state.checked} 
                                Toggle={this.ToggleClick}
                            />
                            <AOS>AOS</AOS>
                         </ContainSwitch>
                         {!filter.isMyArvalCommunity && (
                            <Svg src={filter.filename} />
                         )}
                     </ContainBlockSwitch>
                 </ContentHeader>
                 {/* COLUMN HEADER */}
                 <FieldsContainer>
                     <Row>
                         <FieldHead1 visible={this.checkVisible(ContentArval.length, AOStrueArval.length, this.state.checked)}>
                             <Head>
                                 <Svg1 src={ArvalImg} />
                                 <HeadTitle>Arval</HeadTitle>
                                 {this.state.arval ?
                                    <ClickMoreLess onClick={(e) => this.ClickArval(e)}>-</ClickMoreLess>
                                    : <ClickMore onClick={(e) => this.ClickArval(e)}>+</ClickMore>
                                 }
                             </Head>
                         </FieldHead1>
                         <FieldHead2 visible={this.checkVisible(ContentChef.length, AOStrueChef.length, this.state.checked)}>
                             <Head>
                                 <Svg1 src={ChefDeParcImg} />
                                 <HeadTitle>Responsable de parc</HeadTitle>
                             </Head>
                         </FieldHead2>
                         <FieldHead3 visible={this.checkVisible(ContentConduc.length, AOStrueConduc.length, this.state.checked)}>
                             <Head>
                                 <Svg1 src={ConducteurImg} />
                                 <HeadTitle>Conducteur</HeadTitle>
                             </Head>
                         </FieldHead3>
                     </Row>
                 {/* COLUMN CONTENT */}
                     <Row>
                         {/* ARVAL */}
                         <Column1 visible={ContentArval.length}>
                             {ContentArval.map(Content =>
                                <BlockElement  key={Content.id}>
                                    <BlockContent>
                                        {Content.number ? 
                                            <Nbr>{Content.number}</Nbr>
                                         : null }
                                        <ContentP dangerouslySetInnerHTML={{__html: Content.value}} /> 
                                    </BlockContent>
                                    <div>
                                        {Content.files ?
                                            Content.files.map(file =>
                                                <File key={file.id} href={file.filename} target="_blank" rel="noopener">Télécharger le fichier</File>
                                                )
                                        : null
                                        }
                                        {Content.links ?
                                            Content.links.map(link =>
                                                <File key={link.id} href={link.url} target="_blank" rel="noopener">Voir le lien</File>
                                                )
                                        : null
                                        }
                                    </div>
                                </BlockElement>
                                
                             )}
                             {this.state.arval === true ?
                              IsPlusTrueArval.map(Content => 
                                <BlockElementPlusArval visible={IsPlusTrueArval.length} key={Content.id}>
                                    <BlockContent>
                                        {/* <Nbr>{Content.position}</Nbr> */}
                                        <ContentP dangerouslySetInnerHTML={{__html: Content.value}} /> 
                                    </BlockContent>
                                    <div>
                                        {Content.files ?
                                            Content.files.map(file =>
                                                <File key={file.id} href={file.filename} target="_blank" rel="noopener">Télécharger le fichier</File>
                                                )
                                        : null
                                        }
                                        {Content.links ?
                                            Content.links.map(link =>
                                                <File key={link.id} href={link.url} target="_blank" rel="noopener">Voir le lien</File>
                                                )
                                        : null
                                        }
                                    </div>
                                </BlockElementPlusArval>
                             )
                             : null 
                             }
                             {this.state.checked === true ?
                              AOStrueArval.map(Content => 
                                <BlockElementAOS key={Content.id}>
                                    <BlockContentAOS>
                                        {/* <Nbr>{Content.position}</Nbr> */}
                                    <ContentP dangerouslySetInnerHTML={{__html: Content.value}} />
                                    </BlockContentAOS>
                                    <div>
                                        {Content.files ?
                                            Content.files.map(file =>
                                                <File key={file.id} href={file.filename} target="_blank" rel="noopener">Télécharger le fichier</File>
                                                )
                                        : null
                                        }
                                        {Content.links ?
                                            Content.links.map(link =>
                                                <File key={link.id} href={link.url} target="_blank" rel="noopener">Voir le lien</File>
                                                )
                                        : null
                                        }
                                    </div>
                                </BlockElementAOS>
                             )
                             : null 
                             }
                         </Column1>

                         {/* CHEF DE PARC */}
                         <Column2 visible={this.checkVisible(ContentChef.length, AOStrueChef.length, this.state.checked)}>
                             {ContentChef.map(Content => 
                                <BlockElement key={Content.id}>
                                 <BlockContent>
                                    {Content.number ? 
                                        <Nbr>{Content.number}</Nbr>
                                        : null }
                                    <ContentP dangerouslySetInnerHTML={{__html: Content.value}} /> 
                                 </BlockContent>
                                <div>
                                    {Content.files ?
                                        Content.files.map(file =>
                                            <File key={file.id} href={file.filename} target="_blank" rel="noopener">Télécharger le fichier</File>
                                            )
                                    : null
                                    }
                                    {Content.links ?
                                        Content.links.map(link =>
                                            <File key={link.id} href={link.url} target="_blank" rel="noopener">Voir le lien</File>
                                            )
                                    : null
                                    }
                                </div>
                                </BlockElement> 
                             )
                             }
                             {this.state.checked === true ?
                              AOStrueChef.map(Content => 
                                <BlockElementAOS key={Content.id}>
                                    <BlockContentAOS>
                                        {/* <Nbr>{Content.position}</Nbr> */}
                                    <ContentP dangerouslySetInnerHTML={{__html: Content.value}} /> 
                                    </BlockContentAOS>
                                    <div>
                                        {Content.files ?
                                            Content.files.map(file =>
                                                <File key={file.id} href={file.filename} target="_blank" rel="noopener">Télécharger le fichier</File>
                                                )
                                        : null
                                        }
                                        {Content.links ?
                                            Content.links.map(link =>
                                                <File key={link.id} href={link.url} target="_blank" rel="noopener">Voir le lien</File>
                                                )
                                        : null
                                        }
                                    </div>
                                </BlockElementAOS>
                             )
                             : null 
                             }
                         </Column2>

                         {/* CONDUCTEUR */}
                         <Column3 visible={this.checkVisible(ContentConduc.length, AOStrueConduc.length, this.state.checked)}>
                             {ContentConduc.map(Content => 
                                <BlockElement key={Content.id}>
                                    <BlockContent>
                                        {Content.number ? 
                                            <Nbr>{Content.number}</Nbr>
                                         : null }
                                    <ContentP dangerouslySetInnerHTML={{__html: Content.value}} /> 
                                    </BlockContent> 
                                    <div>
                                        {Content.files ?
                                            Content.files.map(file =>
                                                <File key={file.id} href={file.filename} target="_blank" rel="noopener">Télécharger le fichier</File>
                                                )
                                        : null
                                        }
                                        {Content.links ?
                                            Content.links.map(link =>
                                                <File key={link.id} href={link.url} target="_blank" rel="noopener">Voir le lien</File>
                                                )
                                        : null
                                        }
                                    </div>  
                                </BlockElement> 
                             )}
                             {this.state.checked === true ?
                              AOStrueConduc.map(Content => 
                                <BlockElementAOS key={Content.id}>
                                    <BlockContentAOS>
                                        {/* <Nbr>{Content.position}</Nbr> */}
                                    <ContentP dangerouslySetInnerHTML={{__html: Content.value}} /> 
                                    </BlockContentAOS>
                                    <div>
                                        {Content.files ?
                                            Content.files.map(file =>
                                                <File key={file.id} href={file.filename} target="_blank" rel="noopener">Télécharger le fichier</File>
                                                )
                                        : null
                                        }
                                        {Content.links ?
                                            Content.links.map(link =>
                                                <File key={link.id} href={link.url} target="_blank" rel="noopener">Voir le lien</File>
                                                )
                                        : null
                                        }
                                    </div>
                                </BlockElementAOS>
                             )
                             : null 
                             }
                         </Column3>
                     </Row>
                 </FieldsContainer>
                 <BackgroundLine>
                <CreateGlobalStyle />
                <ContainerLevel>
                    {AOSbuttonFalse.map(button =>
                        <ButtonLevel
                            onClick={(e) => this.pushId(e,button.id)}
                            key={button.id}
                            clickLevel={this.state.idButton === button.id || (this.state.idButton == null ? button.position === 0 : null)}
                        >{button.name}</ButtonLevel>
                    )}
                    {this.state.checked === true ?
                        AOSbuttonTrue.map(button =>
                            <ButtonAOS
                            key={button.id}
                            >{button.name}</ButtonAOS>
                            
                        )
                    : null 
                    }
                </ContainerLevel>
            </BackgroundLine>

             </Container>
             {/* {isMyArvalCommunity && (
                 <ContainCommunity>
                    <Link 
                        to={`/${isMyArvalCommunity.slug}/0`}
                        style={{textDecoration: 'none',
                        borderRadius: '30px',
                        color: '#1d6d5e',
                        border: '1px solid #1d6d5e',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        paddingTop: '11px',
                        paddingBottom: '8px',
                        textTransform: 'uppercase',
                        fontSize: '19px',
                        marginLeft: '25px',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontFamily: 'bnpp-sans-cond-light-v2',}}
                        onClick={() => this.props.toggle(isMyArvalCommunity.id)}
                        >
                        Accéder à my arval community
                    </Link>
                 </ContainCommunity>
             )} */}
         </div>
        );
    }

}

const mapStateToProps = state => ({
    filter: state.parcours.filter,

});

export default connect(mapStateToProps)(Commande);