import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import { apiMiddleware } from 'redux-api-middleware';
import allReducers from '../reducers/index'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const config = {
    key: "root",
    storage,
    debug: true,
    version: 1,
};

const Reducer = persistReducer(config, allReducers);

const store = createStore(Reducer, composeEnhancers(
    applyMiddleware(thunk, apiMiddleware),
));

const persistor = persistStore(store);

const configureStore = () => {
    return {store, persistor};
};

export default configureStore();