import React from 'react'
import { connect } from "react-redux";
import { fetchParcours } from "../../actions/index";
import Button from './Button'
import styled from 'styled-components'
import Commande from './commande';
import _ from 'lodash'

const Container = styled.div`
    background: #eaeaea;
    background-position: center;
    height: calc(100% - 200px);
    padding: 20px;
    position: relative;
`

const Test = styled.div`
    display: flex;
    margin-top: 12px;
    position: relative;
`

class Parkour extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null,
            buttonClick: false,
            active: null,
            clickLevel: false,
            idButtonTop: null,
            idbuttonLevel: null,
        };
    }

    componentDidMount() {
        let slug = this.props.match.params.slug;
        this.props.dispatch(fetchParcours(slug));
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.match.params.slug !== this.props.match.params.slug) {
            this.props.dispatch(fetchParcours(this.props.match.params.slug));
        }
    }

    toggle(id){
        // console.log('toggle click', id);
        this.setState({
            idButtonTop: id,
            idbuttonLevel: null
        });

        if (this.state.active === id) {
            this.setState({active : null});
        } else {
            this.setState({active : id});
         }
    }

    
    render() {
        const { parcours, filter, buttonName } = this.props;

        let filteredParcours = _.filter(parcours, function(o) { return !o.isMyArvalCommunity; });

        const {handleClick} = this;
        const {content, buttonClick} = this.state;

        const FilterId = _.filter(buttonName, function(o) {
            return o.id === content;
        })

        // console.log("parkour: ", this.props)

        return(
            <Container>
                <Test>
                {filteredParcours === undefined ? null :
                    filteredParcours.map(parcour =>
                        <Button
                            key={parcour.id}
                            parcours={parcour}
                            handleClick={handleClick}
                            buttonClick={buttonClick}
                            toggle={this.toggle.bind(this)}
                            active={this.state.active}
                        />
                    )
                }
                </Test>
                {filter === undefined ? null :
                    <Commande
                        buttonName={buttonName}
                        slug={this.props.match.params.slug}
                        FilterId={FilterId[0]}
                        parcours={parcours}
                        toggle={this.toggle.bind(this)}
                        idButtonTop={this.state.idButtonTop}
                        //clickLevel={this.state.clickLevel}
                        idbuttonLevel={this.state.idbuttonLevel}
                        history={this.props.history}
                    />
                }
            </Container>
        )
    }
}


const mapStateToProps = state => ({
    parcours: state.parcours.items,
    filter: state.parcours.filter,
    buttonName: state.parcours.buttonName,
    loading: state.parcours.loading,
    error: state.parcours.error
});

export default connect(mapStateToProps)(Parkour);