import React from 'react'
import { connect } from "react-redux";
import { fetchParcours } from "../../actions/index";
import styled from 'styled-components';
import Button from './Button'
import LineImg from '../../assets/images/line.svg';

const Container = styled.div`
    background: #eaeaea url('${LineImg}') 0 0 no-repeat;
    background-position: center;
    height: calc(100% - 200px);
    padding: 20px;
    position: relative;
`

class Home extends React.Component{
    componentDidMount() {
        this.props.dispatch(fetchParcours());
    }

    componentDidUpdate(nextProps){
        if(nextProps.slug !== this.props.slug){
            this.props.dispatch(fetchParcours(nextProps.slug));
        }
    }

    render() {
    const { parcours } = this.props;
    //console.log('error', this.props.error);

        return(
            <Container>
                {parcours === undefined ? null :
                    parcours.map(parcour =>
                        <Button
                            key={parcour.id}
                            parcours={parcour}
                        />
                    )
                }
            </Container>
        )
    }
}


const mapStateToProps = state => ({
    parcours: state.parcours.items,
    loading: state.parcours.loading,
    error: state.parcours.error
});

export default connect(mapStateToProps)(Home);