import React from 'react'
import styled from 'styled-components'

const CheckBoxWrapper = styled.div`
    position: relative;
    margin-top: 12px;
    margin-left: 13px;
`

const Switch = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    width: 42px;
    height: 26px;
    border-radius: 15px;
    background: #fff;
    cursor: pointer;
    border: 1px solid #ff7a37;

    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 23px;
        height: 23px;
        margin: 3px;
        margin-top: 2px;
        background: #ffffff;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
        transition: 0.2s;
    }
`

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    opacity: 0;
    z-index: 1;
    border-radius: 15px;
    width: 42px;
    height: 26px;

        &:checked + ${Switch} {
            background: #ff7a37;

        &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 23px;
            height: 23px;
            margin-top: 2px;
            margin-left: 19px;
            transition: 0.2s;
        }
    }
`

class ToggleSwitch extends React.Component{

    render(){
        const { Toggle } = this.props;
        return (
            <div>
                <CheckBoxWrapper>
                    <Checkbox id="checkbox" 
                        onChange={Toggle}
                    />
                    <Switch
                        htmlFor="checkbox"
                    />
                </CheckBoxWrapper>
            </div>
        );
    }

}

export default ToggleSwitch;