import React from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import bnppFontBold from '../../assets/fonts/BNP-Font/bnpp-sans-cond-bold-v2.ttf';
import bnppFontLight from '../../assets/fonts/BNP-Font/bnpp-sans-cond-light-v2.ttf';
import bnppBold from '../../assets/fonts/BNP-Font/bnpp-sans-bold.ttf';

import { Link } from "react-router-dom";

const CreateGlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'bnpp-sans-cond-bold-v2';
        src: url(${bnppFontBold});
    }
    @font-face {
        font-family: 'bnpp-sans-cond-light-v2';
        src: url(${bnppFontLight});
    }
    @font-face {
        font-family: 'bnpp-sans-bold';
        src: url(${bnppBold});
    }
`

const StyledButton = styled.span`
    background-color: ${props => props.color};
    border-radius: 30px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'bnpp-sans-cond-light-v2';
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 11px 10px;
    padding-top: 13px;
    height: 8px;
    text-decoration: none;

    &.active{
    }
`

const Number = styled.span`
    font-size: 0.75rem!important;
    box-sizing: border-box;
    display: inline-block;
    height: 16px;
    border: 1px solid #ffffff;
    border-radius: 15px;
    padding: 2px 5px;
    font-weight: 700;
    line-height: 1;
    margin-right: 5px;
    margin-top: -2px;
    z-index: 1;
    color: #fff;
`


const BlockImg = styled.div`
    height: 60px;
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
`

const SvgImage = styled.img`
    height: 55px;
`

const MiseEnPlace = styled.div`
    flex:1;
    position: relative;
    margin-right: -15px;

    &:last-of-type{
        margin-right: 0;
    }

    &:nth-of-type(1) {
        flex: 1.3;
    }
    &:nth-of-type(2) {
        flex: 1.3;
    }
    &:nth-of-type(3) {
        flex: 1.2;
    }
    &:nth-of-type(5) {
        flex: 0.9;
    }
    &:nth-of-type(6) {
        flex: 0.8;
    }
    &:nth-of-type(7) {
        flex: 1.1;
    }
    &:nth-of-type(8) {
    }
`


class Button extends React.Component{
    constructor(props) {
        super(props);

        this.img = this.img.bind(this);
        this.text = this.text.bind(this);
    }

    img(id){
        if (this.props.active === id) {
          return "37px";
        }
        return "";
    }

    text(id){
        if (this.props.active === id) {
          return "0px";
        }
        return "";
    }

    render(){
        const parcours = this.props.parcours;
        return (
            <MiseEnPlace>
                <CreateGlobalStyle />
                        <Link 
                            to={`/${parcours.slug}/0`}
                            style={{textDecoration: 'none'}}
                            onClick={() => this.props.toggle(parcours.id)}
                            >
                    <div>
                        <BlockImg 
                            style={{height: this.img(parcours.id)}}
                        >
                            <SvgImage src={parcours.filename} alt={parcours.name} />
                        </BlockImg>
                            <StyledButton
                                id={parcours.id}
                                color={parcours.color}
                                style={{fontSize: this.text(parcours.id)}}
                                >
                                <Number>{parcours.position + 1}</Number>
                                {parcours.name}
                            </StyledButton>
                    </div>              
                        </Link>
            </MiseEnPlace>
        );

    }

}

export default Button;