import React from 'react';
import { Route } from "react-router-dom";
import Header from './components/Layout/Header'
import Footer from './components/Layout/Footer'
import Home from './components/Home/Home';
import Parkour from './components/Parkour/Parkour';
import './index.css';
import commande from './components/Parkour/commande';

function App() {
  return (
    <div className="App">
      <div className="App-container">
        <Header />
        <Route exact path="/" component={Home} />
        <Route exact path="/:slug/:id" component={Parkour} />
        <Footer />
      </div>
    </div>
  );
}

export default App;
