import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import configureStore from './store';
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import styled, {createGlobalStyle} from 'styled-components'
import bnppBold from './assets/fonts/BNP-Font/bnpp-sans-bold.ttf';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import LocalServiceWorkerRegister from './sw-register';

const CreateGlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'bnpp-sans-bold';
        src: url(${bnppBold});
    }
`
const OrientationP = styled.p`
    font-family: 'bnpp-sans-bold';
`

const {store, persistor} = configureStore;
ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter>
                <DeviceOrientation lockOrientation={'landscape'}>
                    <Orientation orientation='landscape' alwaysRender={false}>
                        <App />
                    </Orientation>
                    <Orientation orientation='portrait' alwaysRender={false}>
                        <CreateGlobalStyle/>
                        <div className="orientation-portrait">
                            <OrientationP>Veuillez mettre en mode paysage s'il vous plaît.</OrientationP>
                        </div>
                    </Orientation>
                </DeviceOrientation>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
//serviceWorker.unregister();
LocalServiceWorkerRegister();
