import React from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import bnppFontBold from '../../assets/fonts/BNP-Font/bnpp-sans-cond-bold-v2.ttf';
import bnppFontLight from '../../assets/fonts/BNP-Font/bnpp-sans-cond-light-v2.ttf';

const Nav = styled.nav`
    height: 80px;
    display: flex;
    background-color: #fffff;
    `

const Container = styled.div`
    width: 100%;
    padding: 0 25px;
    display: flex;
    align-items: center;
`
   
const Text = styled.p`
    font-family: 'bnpp-sans-cond-bold-v2';
    text-transform: uppercase;
    font-weight: bold;
    font-size: 31px;
    flex: 1;
    margin-top: 8px;
    margin: 0;
`

const CreateGlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'bnpp-sans-cond-bold-v2';
        src: url(${bnppFontBold});
    }
    @font-face {
        font-family: 'bnpp-sans-cond-light-v2';
        src: url(${bnppFontLight});
    }
`

const Slogan = styled.p`
    font-family: 'bnpp-sans-cond-light-v2';
    font-size: 20px;
    flex: 3;
    line-height: 2.3;
`

class Footer extends React.Component{
    render(){
        return(
            <Nav>
            <CreateGlobalStyle />
                <Container>
                    <Text>Mon parcours client</Text>
                    <Slogan>Arval à mes côtés du départ à l'arrivée !</Slogan>
                </Container>
            </Nav>
        )
    }
}

export default Footer;