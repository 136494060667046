import React from 'react'
import { Link } from "react-router-dom";
import styled, {createGlobalStyle} from 'styled-components'
import bnppFontBold from '../../assets/fonts/BNP-Font/bnpp-sans-cond-bold-v2.ttf';
import bnppFontLight from '../../assets/fonts/BNP-Font/bnpp-sans-cond-light-v2.ttf';

const Button1 = styled.span`
    background-color: ${props => props.color};
    width: 90px;
    border-radius: 30px;
    color: #ffffff;
    position: absolute;
    padding-left: 61px;
    padding-right: 30px;
    padding-top: 7px;
    padding-bottom: 3px;
    left: 15px;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'bnpp-sans-cond-light-v2';
    font-size: 19px;
`

const Button2 = styled(Button1)`
    width: 100px;
    padding-left: 61px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 4px;
`

const Button3 = styled(Button1)`
    width: 73px;
    border-radius: 30px;
    padding-left: 61px;
    padding-right: 30px;
    padding-top: 7px;
    padding-bottom: 4px;
`

const Button4 = styled(Button1)`
    width: 133px;
    padding-left: 64px;
    padding-right: 20px;
    padding-top: 17px;
    right: 0;
    padding-bottom: 13px;
`

const Button5 = styled(Button1)`
    width: 95px;
    top: 28px;
    padding-left: 61px;
    padding-right: 20px;
    padding-top: 17px;
    padding-bottom: 17px;
`
const Button6 = styled(Button1)`
    width: 120px;
    padding-left: 61px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
`
const Button7 = styled(Button1)`
    width: 109px;
    padding-left: 61px;
    padding-right: 32px;
    padding-top: 9px;
    padding-bottom: 6px;
`

const Button8 = styled(Button1)`
    width: 126px;
    padding-left: 61px;
    padding-right: 0px;
    padding-top: 16px;
    padding-bottom: 16px;
    bottom: -38px;
    left: 3px;
`

const Number1 = styled.span`
    border: 1px solid #ffffff;
    border-radius: 15px;
    padding: 6px 12px;
    left: 27px;
    position: absolute;
    margin-top: 9px;
    z-index: 1;
    color: #fff;
    font-family: 'bnpp-sans-cond-light-v2';
`

const Number2 = styled(Number1)`
    padding: 6px 12px;
    margin-left: 0px;
    margin-top: 10px;
`

const Number3 = styled(Number1)`
    padding: 6px 12px;
    margin-left: 0px;
    margin-top: 9px;
`
const Number4 = styled(Number1)`
    padding: 6px 12px;
    margin-left: 0px;
    top: 0px;
`
const Number5 = styled(Number1)`
    padding: 6px 12px;
    margin-left: 0px;
    top: 37px;
    margin-top: 0px;
`
const Number6 = styled(Number1)`
    padding: 6px 12px;
    margin-left: 0x;
    margin-top: 8px;
`
const Number7 = styled(Number1)`
    padding: 6px 12px;
    margin-left: 0px;
    margin-top: 11px;
`

const Number8 = styled(Number1)`
    padding: 6px 12px;
    margin-left: 0;
    margin-top: -4px;
    left: 14px;
`

const Container2 = styled.div`
    position: absolute;
    left: 312px;
`

const Container3 = styled.div`
    position: absolute;
    top: 33px;
    right: 263px;
`

const Container4 = styled.div`
    position: absolute;
    top: 183px;
    right: 33px;
    width: 230px;
`

const Container5 = styled.div`
    width: 360px;
    top: 246px;
    left: 287px;
    position: absolute;
`

const Container6 = styled.div`
    width: 286px;
    bottom: 233px;
    left: 19px;
    position: absolute;
`

const Container7 = styled.div`
    width: 286px;
    bottom: 218px;
    left: 414px;
    position: absolute;
`

const Container8 = styled.div`
    position: absolute;
    bottom: 60px;
    right: 63px;
`


const BlockImg1 = styled.div`
    margin-left: 53px;
    margin-bottom: 5px;
    margin-top: 29px;
`

const BlockImg2 = styled.div`
    padding-top: 50px;
    position: absolute;
    left: 34px;
    pointer-events: none;
`

const BlockImg5 = styled.div`
    position: absolute;
    right: 37px;
    top: -18px;
`
const BlockImg6 = styled.div`
    position: absolute;
    right: 21px;
    top: 16px;
`
const BlockImg7 = styled.div`
    position: absolute;
    right: 0;
    top: 16px;
`

const BlockImg8 = styled.div`
    margin-left: 35px;
    margin-bottom: 5px;
    margin-top: 29px;
`

const SvgImage = styled.img`
    width: 130px;
`

const SvgImage3 = styled.img`
    width: 130px;
    margin-left: -96px;
`
const SvgImage6 = styled.img`
    width: 110px;
`
const SvgImage8 = styled.img`
    right: 0;
    width: 130px;
    margin-bottom: 20px;
`

const MiseEnPlace = styled.div`
    position: absolute;
`

const BlockButton4 = styled.div`
    position: relative;
    right: 0;
`

const CreateGlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'bnpp-sans-cond-bold-v2';
        src: url(${bnppFontBold});
    }
    @font-face {
        font-family: 'bnpp-sans-cond-light-v2';
        src: url(${bnppFontLight});
    }
`

class Button extends React.Component{

    render(){
        const parcours = this.props.parcours;

        // Mise en place de la relation
        if(parcours.id === 1){
            return (
                <MiseEnPlace>
                    <CreateGlobalStyle />
                    <Link to={`/${parcours.slug}/0`}>
                    <BlockImg1>
                        <SvgImage src={parcours.filename} alt={parcours.name} />
                    </BlockImg1>
                    <Number1>{parcours.id}</Number1>
                        <Button1 
                            id={parcours.id}
                            color={parcours.color}
                            // href={"/" + parcours.slug}
                        >
                            {parcours.name}
                        </Button1>
                    </Link>
                </MiseEnPlace>
            );
        }
        // Les prestations de services 
        else if(parcours.id === 2){
            return (
                <Container2>
                    <Link to={`/${parcours.slug}/0`}>
                    <Number2>{parcours.id}</Number2>
                    <Button2 
                        id={parcours.id}
                        color={parcours.color}
                        // href={"/"+parcours.slug}
                    >
                        {parcours.name}
                    </Button2>
                    <BlockImg2>
                        <SvgImage src={parcours.filename} alt={parcours.name} />
                    </BlockImg2>
                    </Link>
                </Container2>
            );
        } 
        // Commande et livraison
        else if(parcours.id === 3){
            return (
                <Container3>
                    <Link to={`/${parcours.slug}/0`}>
                    <Number3>{parcours.id}</Number3>
                    <Button3 
                        id={parcours.id}
                        color={parcours.color}
                        // href={"/"+parcours.slug}
                    >
                        {parcours.name}
                    </Button3>
                    <div>
                        <SvgImage3 src={parcours.filename} alt={parcours.name} />
                    </div>
                    </Link>
                </Container3>
            );
        } 
        // La vie du contrat
        else if(parcours.id === 4){
            return (
                <Container4>
                        <Link to={`/${parcours.slug}/0`}>
                        <SvgImage src={parcours.filename} alt={parcours.name} />
                    <BlockButton4>
                        <Number4>{parcours.id}</Number4>
                        <Button4
                            id={parcours.id}
                            color={parcours.color}
                            // href={"/"+parcours.slug}
                            >
                            {parcours.name}
                        </Button4>
                    </BlockButton4>
                        </Link>
                </Container4>
            );
        }
        // La facturation
        else if(parcours.id === 5){
            return (
                <Container5>
                    <Link to={`/${parcours.slug}/0`}>
                    <Number5>{parcours.id}</Number5>
                    <Button5
                        id={parcours.id}
                        color={parcours.color}
                        // href={"/"+parcours.slug}
                    >
                        {parcours.name}
                    </Button5>
                    <BlockImg5>
                        <SvgImage src={parcours.filename} alt={parcours.name} />
                    </BlockImg5>
                    </Link>
                </Container5>
            );
        }
        // Le reporting
        else if(parcours.id === 6){
            return (
                <Container6>
                    <Link to={`/${parcours.slug}/0`}>
                    <Number6>{parcours.id}</Number6>
                    <Button6
                        id={parcours.id}
                        color={parcours.color}
                        // href={"/"+parcours.slug}
                    >
                        {parcours.name}
                    </Button6>
                    <BlockImg6>
                        <SvgImage6 src={parcours.filename} alt={parcours.name} />
                    </BlockImg6>
                    </Link>
                </Container6>
            );
        }
        // La satisfaction client
        else if(parcours.id === 7){
            return (
                <Container7>
                    <Link to={`/${parcours.slug}/0`}>
                    <Number7>{parcours.id}</Number7>
                    <Button7
                        id={parcours.id}
                        color={parcours.color}
                        // href={"/"+parcours.slug}
                    >
                        {parcours.name}
                    </Button7>
                    <BlockImg7>
                        <SvgImage src={parcours.filename} alt={parcours.name} />
                    </BlockImg7>
                    </Link>
                </Container7>
            );
        }
        // Fin du contrat
        else if(parcours.id === 8){
            return (
                <Container8>
                    <Link to={`/${parcours.slug}/0`}>
                    <BlockImg8>
                    <SvgImage8 src={parcours.filename} alt={parcours.name} />
                    </BlockImg8>
                    <Number8>{parcours.id}</Number8>
                    <Button8 
                        id={parcours.id}
                        color={parcours.color}
                        // href={"/"+parcours.slug}
                    >
                        {parcours.name}
                    </Button8>
                    </Link>
                </Container8>
            );
        }
        else {
            return null;
        }

    }

}

export default Button;