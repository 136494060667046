export const FETCH_PARCOURS_BEGIN   = 'FETCH_PARCOURS_BEGIN';
export const FETCH_PARCOURS_SUCCESS = 'FETCH_PARCOURS_SUCCESS';
export const FETCH_PARCOURS_FAILURE = 'FETCH_PARCOURS_FAILURE';

export const fetchParcoursBegin = () => ({
  type: FETCH_PARCOURS_BEGIN
});

export const fetchParcoursSuccess = parcours => ({
  type: FETCH_PARCOURS_SUCCESS,
  payload: { parcours }
});

export const fetchParcoursFailure = error => ({
  type: FETCH_PARCOURS_FAILURE,
  payload: { error }
});