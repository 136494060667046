import {FETCH_PARCOURS_BEGIN, FETCH_PARCOURS_SUCCESS, FETCH_PARCOURS_FAILURE} from '../actions/types'
import _ from 'lodash'

const initialState = {
    items: [],
    loading: false,
    error: null
};

export default function parcoursReducer(state = initialState, action) {
    switch(action.type) {

      case FETCH_PARCOURS_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };

      case FETCH_PARCOURS_SUCCESS:
        let Filter = _.filter(action.payload.parcours, function(o) { return o.slug === action.payload.parcours.slug; });
        let buttonRight = _.map(Filter, 'steps');

        return {
          ...state,
          loading: false,
          items: action.payload.parcours,
          filter: Filter[0],
          buttonName: buttonRight[0]
        };

      // case PERSIST_REHYDRATE:
      //     console.log(action.payload.parcours);
      //     return {
      //       ...state,
      //       persistedState: action.payload
      //     };

      case FETCH_PARCOURS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          items: []
        };

      default:
        return state;
    }
  }
